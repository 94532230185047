.form {
  margin: 0 auto 50px;
}

.LessonsTime {
  display: flex;
  margin-left: 14vw;
}
.LessonsInp {
  margin-left: 5vw;
}

.button {
  font-size: 18px;
}


.LessonsTimeUnit {
  align-self: center;
  margin-bottom: 35px;
}

.fileInput {
  margin-bottom: 72px;
}

.LessonsTimeLabel {
  min-width: 230px;
}

.LessonsTimeInput {
  margin-right: 10px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteLessons {
  font-size: 18px;
  text-decoration: underline;
  margin-left: 26px;
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .checkboxGroup {
    display: block;
  }

  .checkboxGroupLabel {
    margin-bottom: 10px;
  }

  .checkboxGroupTags {
    display: block;
  }

  .checkboxGroupItem {
    margin-bottom: 6px;
  }

  .LessonsTime {
    display: block;
    text-align: left;
  }
}