.form {
  margin: 0 auto 50px;
}
.inputContainer {
  position: relative;
}

.eyeButton {
  position: absolute;
  top: 50%;
  right: 10vw;
  transform: translateY(-50%);
}

@media screen and (max-width: 730px) {
  .inputContainer {
    position: relative;
  }
  
  .eyeButton {
    position: absolute;
    right: 5%;
    top: 2%;
    transform: translateY(-50%);
  }
}