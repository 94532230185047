.buttonContainer {
    position: relative;
  }
  
  .buttonContainer button {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
  }
  