.form {
  margin: 0 auto 50px;
}

.button {
  font-size: 35px;
  font-weight: 700;
}
.single-card__edit {

  color: #000;
  text-decoration: underline;
}
p {
  font-size: clamp(15px, 2vw, 50px);
  font-weight: 900;
}
.inputContainer {
  position: relative;
}

.eyeButton {
  position: absolute;
  top: 50%;
  right: 10vw;
  transform: translateY(-50%);
}
@media screen and (max-width: 1100px) {
  p {
    font-size: 3.5vw;
    font-weight: 700;
    line-height: clamp(15px, 5vw, 50px);
  }
}
@media screen and (max-width: 730px) {
  p {
    font-size: 4.5vw;
    font-weight: 700;
  }
}
@media screen and (max-width: 480px) {
  p {
    font-size: clamp(16px, 3vw, 50px);
    font-weight: 700;
    line-height: clamp(15px, 8vw, 50px);
  }
}
@media screen and (max-width: 380px) {
  p {
    font-size: clamp(16px, 2vw, 50px);
    font-weight: 700;
    line-height: clamp(15px, 8vw, 50px);
  }
}