.form {
  margin: 0 auto 50px;
}

.button {
  font-size: 20px;
}

.inputContainer {
  position: relative;
}

.eyeButton {
  margin-top: 9%;
  transform: translateY(-50%);
}
@media screen and (max-width: 730px) {
  .inputContainer {
    position: relative;
  }
  
  .eyeButton {
    position: absolute;
    right: 5%;
    top: 37%;
    transform: translateY(-50%);
  }
}