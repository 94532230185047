.Form {
  max-width: 1200px;
  padding: 20px 20px 20px 20px;

}
.If {
  width: 110vw;
  height: 65vw;

}
.Button  {
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .modals {
    background-color: #fddefffc;
    padding: 0px;
    border-radius: 5px;
    z-index: 1100;
  }
  
  .modal_content {
   
    padding: 20px;
    border-radius: 5px;
  }
  
  .modal button {
    margin-right: 10px;
  }

