.footer {
  background: #e4648f;
  height: 90px;
  flex: 0 0 auto;
  text-decoration: none;
}

.footer__brand {
    color: #fff;
    font-family: Amatic SC, cursive;
    font-size: 2vw;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 0;
    line-height: normal;
}

.footer__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-evenly;
}

.footer__item {
    margin-right: 40px;
}

.footer__items {
    display: flex;
    list-style: none;
    padding: 0;
}

.footer__link {
    font-family: 'Amatic SC', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #FFFFFF;
}
.link {
    letter-spacing: 0;
    line-height: normal;
}
.footer__adress {
  left: 250px;
  font-family: 'Amatic SC', cursive;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 2vw;
  text-decoration: none;

}

.footer__nomber {
    font-family: 'Amatic SC', cursive;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 2vw;
    letter-spacing: 0;
    line-height: normal;
    text-decoration: none;
}
.footer__text2 {
    font-family: 'Amatic SC', cursive;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 2vw;
    letter-spacing: 0;
    line-height: normal;
    text-decoration: none;
}
a {
    text-decoration: none;
}

@media screen and (max-width: 990px) {
    .footer__brand {
        font-size: 25px;
    }
    .footer__link {
        visibility: hidden;
        display: none;
    }
    .footer__adress {
        font-size: 25px; 
    }
    
    .footer__nomber {
        font-size: 25px;
    }
    .footer__text2 {
        visibility: hidden;
        display: none;
    }
    
    .footer {
        height: 9vw;
      }
}

@media screen and (max-width: 900px) {
    .footer__brand {
        font-size: 25px;
    }
    .footer__link {
        visibility: hidden;
        display: none;
    }
    .footer__adress {
        font-size: 25px;

    }
    
    .footer__nomber {
        font-size: 25px;
    }
    .footer__text2 {
        font-size: 25px;

    }
}
@media screen and (max-width: 770px) {
    .footer__brand {
        font-size: 25px;
    }
    .footer__link {
        visibility: hidden;
        display: none;
    }
    .footer__adress {
        visibility: hidden;
        display: none;
    }
    
    .footer__nomber {
        font-size: 25px;
    }
    .footer__text2 {
        visibility: hidden;
        display: none;
    }
    .footer__container {
        display: block;
        height: 100%;
    }
}
  
@media screen and (max-width: 770px) {
    .footer__brand {
        font-size: 25px;
    }
    .footer__link {
        visibility: hidden;
        display: none;
    }
    .footer__adress {
        visibility: hidden;
        display: none;
    }
    
    .footer__nomber {
        font-size: 25px;
    }
    .footer__text2 {
        visibility: hidden;
        display: none;
    }
}
