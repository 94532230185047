.nav {
  height: 90px;
  width: 21vw;
}
.logo {
  background-image: url(images/logo.png);
  display: flex;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  border: none;
  flex-direction: row;
}
.nav__container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.nav__items {
  display: flex;
  height: 100%;
  flex-direction: row;
  list-style: none;
  padding: 0;
  align-items: center;
  margin-top: 4vw;
}
.nav__item {
  display: flex;
  align-items: center;
  margin-right: 1vw;
  height: 100%;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.nav__item:last-child {
    margin-right: 0;
}

.nav__item_active {
    color: #4A61DD;
}
.nav__item_active:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: 20px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #4A61DD;
}

.nav__link {
  font-style: normal;
  font-weight: 700;
  font-size: clamp(14px, 1.5vw, 70px);
  line-height: 2vw;
  color: inherit;
  cursor: inherit;
  font-family: 'Amatic SC', cursive;
  transition: opacity 0.2s;
  width: 7vw;
}

.nav__link_active {
  border-bottom: 1px solid #f800f9;
  color: #f800f9;
  transform: scale(1.2);
}
.nav__link:hover {
  opacity: 0.8;
  font-size: 23px;
}
.orders-count {
  display: inline-block;
  padding: 0px 12px;
  background: #4A61DD;
  color: #FFF;
  font-weight: 500;
  height: 24px;
  display: flex;
  align-items: center;
  transform: translateY(-7px);
  margin-left: 10px;
  border-radius: 36px;
}

@media screen and (max-width: 990px) {
  .nav__link {
    font-size: 3vw;
    line-height: 3vw;
    width: 10vw;
  }
}

@media screen and (max-width: 900px) {
  .nav__link {
    font-size: 3vw;
  }
  .nav {
    height: 1vw;
  }
  .nav__container {
    margin-top: 8vw;
  
  }    

  .nav__item {
    margin-right: 8px;
  }

  .orders-count {
    height: 16px;
    font-size: 30px;
    padding: 0px 6px;
  }
  .logo {
    margin-top: 1vw;
    width: 30vw;
    height: 6.5vw;
    background-image: url(images/logo.png);
    background-size: cover;
    background-position: 50% 30%;
  }
}
@media screen and (max-width: 840px) {
  
  .logo {
    margin-top: 3vw;
  }
  .nav__container {
    margin-top: 7vw;
    
  }    
}
@media screen and (max-width: 820px) {
  .nav__link {
    font-size: 3vw;
    line-height: 3vw;
  }
  .nav {
    height: 1vw;
  }
  .nav__container {
    margin-top: 6vw;
    
  }      

  .nav__item {
    margin-right: 8px;
  }

  .orders-count {
    height: 16px;
    font-size: 30px;
    padding: 0px 6px;
  }
  .logo {
    margin-top: 3vw;
    width: 30vw;
    height: 6.5vw;
    background-image: url(images/logo.png);
    background-size: cover;
    background-position: 50% 30%;
  }
}

@media screen and (max-width: 770px) {
  .nav {
    height: auto;
    position: relative;
  }

  .nav__link {
    font-size: 4vw;
    padding-bottom: 6px;
    line-height: 4vw;
  }

  .nav__items {
    display: block;
    position: absolute;
    left: -250px;
    height: auto;
    top: 0;
  }

  .orders-count {
    transform: translateY(-3px);
  }

  .nav__item {
    margin-bottom: 20px;
  }
  .logo {
    position: absolute;
    top: -1.5vw;
    width: 46vw;
    height: 10.5vw;
    background-image: url(images/logo.png);
    background-size: cover;
    background-position: 50% 30%;
  }
}
@media screen and (max-width: 730px) {
  .nav {
    height: 50vw;
    left: 10%;
    top: 0;
    position: absolute;
  }

  .nav__link {
    font-size: 4vw;
    padding-bottom: 6px;
  }

  .nav__items {
    display: block;
    position: absolute;
    left: -200px;
    height: auto;
    top: 0;
  }

  .orders-count {
    transform: translateY(-3px);
  }

  .nav__item {
    margin-bottom: 20px;
  }
  .logo {
    object-fit: cover;
    height: 10vw;
    width: 43vw;
    left: 2vw;
    top: -1vw;
    position: absolute;
  }
}
@media screen and (max-width: 580px) {
  .nav {
    left: 12%;

  }
  .nav__link {
    font-size: 5vw;
    line-height: 7vw;
  }

  
  .logo {
    top:2vw;
    height: 9vw;
    }
}
@media screen and (max-width: 440px) {
  
  .logo {
    top: 2vw;
    height: 10vw;
  }
  .nav__link {
    font-size: 8vw;
    line-height: 10vw;
  }
}

