.button {
  margin: 3vw;
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  background: none;
  border-radius: 6px;
  padding: 0px 30px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color 0.8s ease-in-out, transform 0.8s ease-in-out, background-color 0.8s ease-in-out;
  outline: none;
  user-select: none;
  cursor: pointer;
  width: max-content;
  box-sizing: border-box;
}

.button:active{
  box-shadow: 0 0 7px #ddd;
  
}

.button_style_light {
  border: 1px solid #DEE2F2;
  background: #FFF;
  color: #8508f3;
}

.button_style_light:hover {
  background: #D8DEFF;
  color: #8508f3;
  border: 1px solid transparent;
  transform: scale(1.1);
  background-color: black;
}

.button_style_light-blue {
  background: #F0F3FF;
  color: #8508f3;
}

.button_style_light-blue:hover {
  background: #D8DEFF;
  color: #8508f3;
  transform: scale(1.1);
  background-color: rgb(147, 108, 255);
}

.button_style_dark-blue {
  background: #8508f3;
  color: #FFF;
}

.button_disabled {
  opacity: .6;
  cursor: not-allowed;
}

.button_style_dark-blue:hover {
  background: #8508f3;
  transform: scale(1.1);
  background-color: rgb(147, 108, 255);
}

.button_style_none {
  padding: 0;
}

.button_style_none:active {
  box-shadow: none;
}


@media screen and (max-width: 940px) {
  .button {
    margin: 1%;
    font-size: 3vw !important;
    padding: 0 3%;
    height: 4vw;
    line-height: 1vw;
  }
}
