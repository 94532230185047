.textarea {
  margin-bottom: 35px;
  font-family: 'Amatic SC', cursive;
  font-style: 400;
  font-weight: normal;
  font-size: 25px;
}

.textareaLabel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.textareaLabelText {
  padding-right: 30px;
  text-align: left;
  flex: 1;
}

.textareaField {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    resize: none;
    outline: none;
    font-family: 'Amatic SC', cursive;
    font-weight: 700;
    font-size: 30px;
    color: #000;
    padding: 12px 18px;
    width: 100%;
}
.textareaField:focus {
    border: 1px solid #4A61DD;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 730px) {
  .textareaLabel {
    display: block;
    text-align: left;
  }

  .textareaLabelText {
    margin-bottom: 10px;
  }

  .textareaField {
    width: 100%;
  }
}
