.form {
  margin: 0 auto 50px;
}

.lessonsTime {
  display: flex;
  margin-left: 9.5vw;
}

.lessonsTimeUnit {
  align-self: center;
  margin-bottom: 35px;
}

.lessonsTimeLabel {
  min-width: 230px;
}

.lessonsTimeInput {
  margin-left: 14vw;
  width: 20vw
}

.fileInput {
  margin-bottom: 72px;
}

.button {
  font-size: 18px;
}

@media screen and (max-width: 820px) {

  .checkboxGroup {
    display: block;
  }

  .checkboxGroupLabel {
    margin-bottom: 10px;
  }

  .checkboxGroupTags {
    display: block;
  }

  .checkboxGroupItem {
    margin-bottom: 6px;
  }

  .lessonsTime {
    display: block;
    text-align: left;
  }

}