.Album {
  display: inline-block;
  padding: 4px 12px;
  font-weight: 700;
  font-family: 'Amatic SC', cursive;
  font-size: 25px;
  line-height: 1;
  border-radius: 24px;
  margin-right: 8px;
}

.Album_type_orange {
  background: rgba(234, 146, 99, 0.12);
  color: #E26C2D;
}

.Album_type_green {
  background: rgba(73, 182, 78, 0.1);
  color: #49B64E;
}

.Album_type_violet {
  background: rgba(135,117,210,0.1);
  color: #8775D2;
}