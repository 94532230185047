.slideshow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
  
  .slideshow img {
    object-fit: contain;
    max-height: calc(100vh - 80px); 
    max-width: calc(100vw - 40px); 
    width: 100%;
  }
  .buttons {
    display: flex;
}

  .buttons button {
    padding: 10px 20px;
    margin: 10px;
    color: white;
    border: none;
    background-color: #8508f3;
    border-radius: 3px;
  }
  
  .buttons button:hover {
    transform: scale(1.1);
    background-color: rgb(147, 108, 255);
  }
  
@media screen and (max-width: 770px) {
  .slideshow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
  