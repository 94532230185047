.form {
  background: #dd719999;
  max-width: 70%;
  border: 1px solid #ffe7e7;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 10% 10% 10% 10%;
  margin-top: 10vw;
}

@media screen and (max-width: 730px) {
  .form {
    padding: 10% 5% 5% 5%;
  }
  
}
@media screen and (max-width: 460px) {
  .form {
    max-height: 100%;
    max-width: 100vw;
    padding: 20% 5% 10%;
  }
  
}
