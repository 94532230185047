.menuButtons {
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 700;
  font-size:  clamp(14px, 2vw, 55px);
  border-radius: 6px;
  padding: 0.5vw;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color 0.8s ease-in-out, transform 0.8s ease-in-out, background-color 0.8s ease-in-out;
  outline: none;
  user-select: none;
  cursor: pointer;
  width: max-content;
  display: inline-block;
  background: #c824ef;
  color: #FFF;
  transform: translateY(-1.5vw);
  margin-top: 2vw;
  white-space: nowrap;
}
.menuButtons:hover {
  background: #f323e2;
  transform: scale(1.1);
  background-color: rgb(147, 108, 255);
}
.menuLink {
  white-space: nowrap;
  color: #000000;
  background: none;
  border: none;
  cursor: pointer;
  font-size:  clamp(14px, 2vw, 55px);
  display: inline-block;
  padding: 0;
  font-weight: 700;
  line-height: 4vw;
  padding-bottom: 1vw;
  margin-right: 2vw;
  transition: opacity 0.2s;

}
.menuLink1 {
  white-space: nowrap;
  color: #000000;
  background: none;
  border: none;
  cursor: pointer;
  font-size: clamp(14px, 2,5vw, 55px);
  display: inline-block;
  padding: 0;
  font-weight: 700;
  line-height: 4vw;
  padding-bottom: 1vw;
  margin-right: 2vw;
  transition: opacity 0.2s;
}
.menuLink:last-child {
  margin-right: 0px;
}
.menuLink1:last-child {
  margin-right: 0px;
}
.menuLink1:hover {
  opacity: 0.8;
  font-size: 23px;
}
.menuLink:hover {
  opacity: 0.8;
  font-size: 23px;
}
.menu1 {
  margin-left: 2vw;
  display: flex;
  align-items: center;
  margin-right: 3vw;
  max-width: 50vw;
  width: 14vw;
}
.menu2 {
  margin-left: 2vw;
  display: flex;
  align-items: center;
  margin-right: 6vw;
  max-width: 50vw;
  width: 14vw;
}
@media screen and (max-width: 1250px) {
  .menuLink {
    margin-top: 3vw;
  }  
  .menuLink1 {
    margin-top: 3vw;

  } 
  .menu1 {
    align-items: baseline;
    display: flex;
    margin-left: -5vw;
    margin-right: -1vw;

  }
  .menu2 {
    position: absolute;
    right: 10vw;
    align-items: baseline;
    display: flex;
    margin-left: -5vw;
    margin-right: -1vw;

  }
  .menuButtons {
    margin-top: 3vw;
    height: 6vw;
    padding: 0.5vw;
  }
}
@media screen and (max-width: 1100px) {
  .menuLink {
    margin-top: 3vw;
  }  
  .menuLink1 {
    margin-top: 3vw;

  } 
  .menu1 {
    align-items: baseline;
    display: flex;
    margin-left: -5vw;

  }
  .menu2 {
    position: absolute;
    right: 10vw;
    align-items: baseline;
    display: flex;
    margin-left: -5vw;

  }
  .menuButtons {
    margin-top: 3vw;
    height: 6vw;
    padding: 0.5vw;
  }
}


@media screen and (max-width: 900px) {
  .menuLink {
    margin-top: 9%;

  }
  .menu1 {
    margin-top: 1vw;
    margin-right: -1vw;
  }
  .menu2 {
    margin-top: 1vw;
    margin-right: 5vw;
  }
  .menuButtons {
    margin-top: 6%;
  }
  .menuLink1 {
    margin-top: 9%;
  }
}

@media screen and (max-width: 770px) {
  .menu1 {
    margin-top: 4vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 1vw;
  }
  .menu2 {
    margin-top: 4vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 1vw;
  }
  .menuLink {
    padding-bottom: 0px;
    font-size: 4vw;
    margin-right: 8vw;
  }
  .menuLink1 {
    padding-bottom:3vw;
    font-size: 4vw;
    margin-right: 8vw;
  } 
  .menuButtons {
    margin-top: 4vw;
  }
}

@media screen and (max-width: 730px) {
  .menu1 {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 1vw;
    position: absolute;
    right: 1vw;
    top: 0.5vw;
    align-items: center;
  }
  .menu2 {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 1vw;
    position: absolute;
    right: 5vw;
    top: 0.5vw;
    align-items: center;
  }
  .menuLink {
    line-height: 60%;
    padding-bottom: 0px;
    font-size: 2.5vw;
    margin-right: 4vw;
    width: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .menuLink1 {
    line-height: 60%;
    padding-bottom: 3vw;
    font-size: 2.5vw;
    margin-right: 4vw;
    width: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
  } 
  .menuButtons {
    margin-top: 5%;
    height: 5vw;
    font-size: 2vw;
  }
  .menuLink:last-child {
    margin-right: 1vw;
  }
  .menuLink1:last-child {
    margin-right: 1vw;
  }
}
@media screen and (max-width: 840px) {
  .menu1 {
    margin-right: 3vw;
    align-items: center;
    display: flex;
    margin-top: 2vw;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }
  .menu2 {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
  }
  .menuLink {
    padding-bottom: 2vw;
    line-height: 2vw;
    font-size: 4vw;
    margin-right: 4vw;
  }
  .menuLink1 {
    padding-bottom: 2vw;
    line-height: 2vw;
    font-size: 4vw;
    margin-right: 4vw;
    
  } 
  .menuButtons {
    margin-top: 1.5vw;
    height: 6vw;
    font-size: 4vw;
  }
  .menuLink:last-child {
    font-size: 4.5vw;
    margin-top: 2vw;
    line-height: 42%;
    margin-left: -35%;
    margin-right: 11vw;
  }
  .menuLink1:last-child {
    margin-right: 1vw;
  }
}
@media screen and (max-width: 460px) {
  .menu1 {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 1vw;
    position: absolute;
    right: 6vw;
    top: 5vw;
    align-items: center;
  }
  .menu2 {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 1vw;
    position: absolute;
    right: 11vw;
    top: 1vw;
    align-items: center;
  }
  .menuLink {
    margin-left: 15%;
    padding-bottom: 4vw;
    font-size: 2.5vw;
    margin-right: 4vw;
    line-height: 60%;
    
  }
  .menuLink1 {
    font-size: 5.5vw;
    line-height: 42%;
    margin-left: -35%;
    margin-right: 11vw;
  } 
  .menuButtons {
    margin-left: 15%;
    height: 2%;
    font-size: 2vw;
    padding: 5%;
    position: relative;
    top: 10%;
  }
}