
.gallery_item {
    font-family: 'Amatic SC', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: left;
}
  
.card {
    font-family: 'Amatic SC', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
}

.title1 {
    color: #fe26ff;
    font-family: Amatic SC, cursive;
    font-size: 3vw;
    font-style: normal;
    font-weight: 900;
    margin-bottom: 23px;
    margin-top: 7vw;
    text-align: left;
}
@media screen and (max-width: 768px) {
    .gallery {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .title1 {
      margin-top: 10vw;
      font-size: 35px;
      }
}
@media screen and (max-width: 990px) {
  .title1 {
    margin-top: 9vw;
    font-size: 35px;
    }
}
  