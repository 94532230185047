.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.container {
  text-align: left;
}

.profilePhoto {
  height: 240px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.card__title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 12px;
  padding: 0;
  display: block;
  text-align: left;
}

.titleText {
  margin-bottom: 23px;
}

.buttonSubscribe {
  margin-bottom: 46px;
}
.button {
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 3.5vw;
  background: none;
  border-radius: 6px;
  padding: 0px 24px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: background 0.2s;
  outline: none;
  user-select: none;
  cursor: pointer;
  width: max-content;
  box-sizing: border-box;
}
.button:active {
  box-shadow: 0 0 7px #ddd;
}

@media screen and (max-width: 770px) {
  .title {
    display: block;
  }
}

@media screen and (max-width: 940px) {
  .button {
    font-size: 12px!important;
    padding: 0px 12px;
    height: 32px;
    line-height: 30px;
  }
}