.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.label {
  flex: 1;
  text-align: left;
}

.checkboxGroupItems {
  flex-basis: 370px;
  display: flex;
}

@media screen and (max-width: 530px) {
  .checkboxGroupItems {
    display: flex;
    flex-basis: 370px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
}
