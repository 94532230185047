.form {
  margin: 0 auto 50px;
}


.fileInput {
  margin-bottom: 72px;
}

.button {
  font-size: 18px;
}
.textareaField {
  flex-basis: none;
}
.aboutInp {
  margin-left: 5vw;
  margin-bottom: 2vw;
}

@media screen and (max-width: 820px) {
 
  .checkboxGroup {
    display: block;
  }

  .checkboxGroupLabel {
    margin-bottom: 10px;
  }

  .checkboxGroupTags {
    display: block;
  }

  .checkboxGroupItem {
    margin-bottom: 6px;
  }

}