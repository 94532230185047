.input {
  position: relative;
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 700;
  height: 5vw;
}

.inputLabel {
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
  font-size: 16px;
  transition: all 0.3s ease;
} 

.inputLabelText {
  font-size: 2vw;
  left: 6vw;
  position: absolute;
  top: 37%;
  color: #555;
  transform: translateY(-50%);
  font-size: 25px;
  transition: all 0.3s ease;
}

.inputField {
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 700;
  flex-basis: 370px;
  padding: 2% 10%;
  border-radius: 6px;
  font-size: 25px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  width: 80%;
}
.inputLabelTextFocused {
  top: 1vw; 
  font-size: 25px; 
} 

.inputLabelTextFilled {
  top: 1vw;
  font-size: 25px;
}
.inputField:focus + .inputLabel .inputLabelText,
.inputField:not(:placeholder-shown) + .inputLabel .inputLabelText,
.inputField:hover + .inputLabel .inputLabelText {
  top: -24px;
  font-size: 14px;
  color: #666;
}

@media screen and (max-width: 1680px) {
  .input {
    height: 6vw;
  }
  .inputLabelTextFocused {
    top: -1.5vw; 
    font-size: 25px; 
  } 
  
  .inputLabelTextFilled {
    top: -1.5vw;
    font-size: 25px;
  }
  .inputField:focus + .inputLabel .inputLabelText,
  .inputField:not(:placeholder-shown) + .inputLabel .inputLabelText,
  .inputField:hover + .inputLabel .inputLabelText {
    top: -1.5px;
    font-size: 14px;
    color: #666;
}}
@media screen and (max-width: 1250px) {
    .input {
      height: 7vw;
      }
      .inputLabelTextFocused {
        top: 0.5vw; 
        font-size: 25px; 
      } 

      .inputLabelTextFilled {
        top: 0.5vw;
        font-size: 25px;
      }
      .inputField:focus + .inputLabel .inputLabelText,
      .inputField:not(:placeholder-shown) + .inputLabel .inputLabelText,
      .inputField:hover + .inputLabel .inputLabelText {
        top: 0.5px;
        font-size: 14px;
        color: #666;
      }}
@media screen and (max-width: 1100px) {
    .input {
      height: 8vw;
    }
    .inputLabelTextFocused {
      top: -0.2vw; 
      font-size: 25px; 
    } 
    
    .inputLabelTextFilled {
      top: -0.2vw;
      font-size: 25px;
    }
    .inputField:focus + .inputLabel .inputLabelText,
    .inputField:not(:placeholder-shown) + .inputLabel .inputLabelText,
    .inputField:hover + .inputLabel .inputLabelText {
      top: -0.2px;
      font-size: 14px;
      color: #666;
    }
  }
@media screen and (max-width: 930px) {
  .input {
      height: 9vw;
  }
  .inputLabelTextFocused {
      top: -0.2vw; 
      font-size: 25px; 
  } 
    
  .inputLabelTextFilled {
      top: -0.2vw;
      font-size: 25px;
  }
  .inputField:focus + .inputLabel .inputLabelText,
  .inputField:not(:placeholder-shown) + .inputLabel .inputLabelText,
  .inputField:hover + .inputLabel .inputLabelText {
      top: -0.2px;
      font-size: 14px;
      color: #666;
  }
}

@media screen and (max-width: 730px) {
  .input {
    height: 12vw;
  }
  
  .inputLabel {
    display: block;
    text-align: left;
  }

  .inputLabelText {
    margin-bottom: 10px;
    font-size: 25px;
    left: 15%;
    position: absolute;
    top: 50%;
  }

  .inputField {
    width: 100%;
  }
  .inputLabelTextFocused {
    top: -2vw; 
    font-size: 25px; 
  } 
  
  .inputLabelTextFilled {
    top: -2vw;
    font-size: 25px;
  }
  .inputField:focus + .inputLabel .inputLabelText,
  .inputField:not(:placeholder-shown) + .inputLabel .inputLabelText,
  .inputField:hover + .inputLabel .inputLabelText {
    top: -24px;
    font-size: 14px;
    color: #666;
  }
}
@media screen and (max-width: 650px) {
  .input {
    height: 13vw;
  }
}
@media screen and (max-width: 590px) {
  .input {
    height: 14.5vw;
  }
}
@media screen and (max-width: 520px) {
  .input {
    height: 15.5vw;
  }
}
@media screen and (max-width: 460px) {
  .input {
    height: 17.5vw;
  }
}
@media screen and (max-width: 420px) {
  .input {
    height: 19.5vw;
  }
}
@media screen and (max-width: 380px) {
  .input {
    height: 22.5vw;
  }
}
@media screen and (max-width: 290px) {
  .input {
    height: 28.5vw;
  }
}