.card {
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  background: #7df8ff;
}

.card__add {
  display: flex;
  font-size: 14px!important;
  font-weight: 600!important;
  align-items: center;
}

.card__add svg {
  margin-right: 5px;
}

.card__body {
  padding: 24px 24px 12px;
  flex: 1 0 auto;
}

.card__link {
  color: #000;
}

.card__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px 32px;
    flex: 0 0 auto;
}

.card__item {
    margin-right: 8px;
}
.card__text {
    font-family: 'Amatic SC', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 17px;
    color: #000;
    margin: 0 0 15px;
    padding: 0;
}
.card__text:last-child {
    margin-bottom: 0;
}

.card__image {
  height: 20vw;
  width: 100%;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

.card__title {
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 4vw;
  color: #000000;
  margin: 0 0 12px;
  padding: 0;
  display: block;
  text-align: left;
}

.card__time,
.card__author {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.card__time {
  margin-bottom: 15px;
}

.card__time svg,
.card__author svg {
  margin-right: 10px;
}


@media screen and (max-width: 1000px) {
  .card__image {
    height: 40vw;
  }
  .card__title {
    line-height: 5vw;
    font-size: 3vw;
  }

  .card__body {
    padding: 12px;
  }

  .card__footer {
    padding: 12px;
  }
}
@media screen and (max-width: 770px) {
  .card {
    max-width: 100%;
  }
  .card__image {
    height: 66vw;
  }
  .card__title {
    line-height: 8vw;
    font-size: 4vw;
  }
}

@media screen and (max-width: 550px) {
  .card {
    max-width: 100%;
  }
  .card__title {
    line-height: 10vw;
  }
  .card__image {
    height: 61vw;
  }
}
