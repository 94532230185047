.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
}

.label {
  padding-right: 30px;
  text-align: left;
  flex: 1;
  max-width: 340px;
}

.fileInput {
  display: none;
}

.button {
  width: 100%;
  cursor: pointer;
  height: 100%;
  background: #f8c3f5;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  transition: opacity .2s;
}
.button:hover {
  opacity: 0.8;
  font-size: 28px;
  color: #350130
}
.image {
  max-width:  350px;
  max-height: 350px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 2px;
  border: 1px solid black;
}