.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 0;
  color: #1200ff;
}
.text_line {
  background: #00f1ff82;
  border-radius: 8vw;
  display: inline-block;
  width: 80vw;
  font-weight: 900;
  text-align: left;
  padding: 1.5vw;
}
.gradient_text {
  background-clip: text;
  background-image: linear-gradient(90deg, #1600ff, #dd00e1);
  color: #0000;
  text-shadow: 0 0vw 2vw #fdfdfd;
  text-align: center;
  margin: 0 auto;
  max-width: 90%;
}
.imgages {
  background: url(./images/vk.png);
  background-size: cover;
  height: 100%;
  max-height: 50vw;
  max-width: 50vw;
  object-fit: cover;
  width: 50vw;
}
.centered_text {
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
}


@media screen and (max-width: 770px) {
  .title {
    display: block;
  }
  .text_line {
    display: block;
  }
}
