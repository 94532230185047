.title {
  color: red;
  display: flex;
  margin: 0 auto 0;
  flex-direction: column

}

@media screen and (max-width: 770px) {
  .title {
    display: block;
  }
}
