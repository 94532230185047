.container {
  margin: 0 1vw 1vw 1vw;;
  max-width: 95vw;
  padding: 0 35px;
  width: 100%;
}
@media screen and (max-width: 1100px) {
  .container {
    padding: 0 40px;
    margin: 0 auto 0;
    width: 100%;
  }
}
