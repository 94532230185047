.description {
  white-space: pre-wrap;
  padding-bottom: 20px;
  line-height:  30px;
  display: flex;
  flex-direction: column;
  padding-right: 10vw;
}

.description__title {
  font-family: 'Amatic SC', cursive; 
  font-style: normal; 
  font-weight: 700; 
  font-size: 40px;
  line-height: 24px;
  color: #000;
  margin: 0 0 12px;
}


@media screen and (max-width: 820px) {
  .description {
    padding-bottom: 30px;
    padding-right: 1vh;
  }
}