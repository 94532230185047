.header {
  background-color: #ff004a54;
  border: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.centered_text {
  text-align: center;
  margin: 0 auto;
  max-width: 50%;
}
.text {
  color: #28fff7;
  margin-right: 16vw;
  font-weight: 900;
  font-size: 2vw;
  display: flex;
  height: 100%;
  align-items: center;
}

.headerContent {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}   
@media screen and (max-width: 1300px) {
  .header {
    background-size: 30vw;
  }
  .headerContent {
    margin-right: 7%;
  }  
}
@media screen and (max-width: 1100px) {
  .text {
    visibility: hidden;
    display: none;
  }
  .centered_text {
    visibility: hidden;
    display: none;
  }
}
@media screen and (max-width: 770px) {
  .headerContent {
    border: none;
    display: flex;
    align-items: baseline;
  }
  .header {
    height: 14vw;
    background-color: #ff004a54;
    background-size: 46vw;
  }
}
@media screen and (max-width: 900px) {
  .headerContent {
    align-items: flex-start;
    display: flex;
  }
  .header {
    height: 7vw
  }
}
@media screen and (max-width: 990px) {
  .header {
    background-color: #ff004a54;
    background-size: 46vw;
    height: 10vw
  }
}
@media screen and (max-width: 840px) {
  
  .header {
    background-color: #ff004a54;
    background-size: 87vw;
    height: 13vw
  }
}
@media screen and (max-width: 730px) {
  
  .header {
    height: 14vw;
    background-color: #ff004a54;
    background-size: 87vw;
  }
}


@media screen and (max-width: 670px) {
  .header {
    background-color: #ff004a54;
    background-size: 87vw;
    height: 16vw;
  }
}
@media screen and (max-width: 400px) {
  .header {
    background-color: #ff004a54;
    background-size: 87vw;
    height: 20vw;
  }
}

