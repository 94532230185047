.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 7vw auto 0;
  color: #8400e5;
}
.red {
  color: red;
}
.green {
  color: #1e9d00;
}
.mix {
  color: #0011ff;
}

.container {
  text-align: left;
}

.titleText {
  margin-bottom: 23px;
}

.buttonSubscribe {
  margin-bottom: 46px;
}

.frame1 {
  text-align: left;
  position:relative;
  overflow:hidden;
}

.frame2 {
  color:#970303;
  font-size:12px;
  position:absolute;
  top:0px;
}

.frame3 {
  color:#9c0909;
  font-size:12px;
  position:absolute;
  top:14px;
}

.frame4 {
  color:#ca320c;
  font-size:12px;
  position:absolute;
  top:28px;
}

.frame5 {
  position:relative;
}

.frame6 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: right;
  width: 100%;
  height: 800px;
  overflow: hidden;
  position: relative;
  margin-bottom: 4vw;
}
.frame10 {
  text-align: left;
  line-height: 3.2vw;

}

.frame7 {
  width:700px;
  height:100%;
  border:1px solid #e6e6e6;
  border-radius:8px;
  box-sizing:border-box
}

.frame8 {
  box-sizing:border-box;
  text-decoration:none;
  color:#b3b3b3;
  font-size:10px;
  font-family:YS Text,sans-serif;
  padding:0 20px;
  position:absolute;
  bottom:8px;
  width:100%;
  text-align:center;
  left:0;
  overflow:hidden;
  text-overflow:ellipsis;
  display:block;
  max-height:14px;
  white-space:nowrap;
  padding:0 16px;
  box-sizing:border-box
}
.text2 {
  margin: 0;
}

.text {
  margin-top: 20px; 
}
@media screen and (max-width: 1100px) {

  .title {
    display: block;
    margin: 30px auto 0;
  }
  .frame6 {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom: 4vw;
    flex-wrap: nowrap;
    align-items: center;

  }
  .frame10 {
    line-height: 4.2vw;
  }
  .frame7 {
    height: 80vw;
    width: 100%;
    border-radius: 0;
  }
  .frame8 {
    font-size: 8px;
  }
  .frame1 {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: right;
  }
} 

@media screen and (max-width: 770px) {
  .title {
    display: block;
    margin: 30px auto 0;
  }
  .frame6 {
    height: 100%;
    width: 100%; 
  }
  .frame7 {
    width: 100%;
    border-radius: 0;
  }
  .frame8 {
    font-size: 8px;
  }
  .frame1 {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: right;
  }
}
@media screen and (max-width: 730px) {
  .title {
    display: block;
    margin: 30px auto 0;
  }
  .frame6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    text-align: right; 
  }
  .frame7 {    
    width: 100%;
    border-radius: 0;
  }
  .frame10 {
    line-height: 6.2vw;
  }
  .frame1 {
    overflow: hidden;
    position: relative;
    text-align: right;
    width: 100%;
    display: flex;
  }
}
@media screen and (max-width: 480px) {
  .frame10 {
    line-height: 10.2vw;
  }
}
@media screen and (max-width: 380px) {
  .frame10 {
    line-height: 10.2vw;
  }
}