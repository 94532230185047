.description {
  padding-bottom: 20px;
}

.description__title {
  font-family: 'Amatic SC', cursive; 
  font-style: normal; 
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin: 0 0 12px;
}


@media screen and (max-width: 820px) {
  .description {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 770px) {
  .description {
    font-size: 5vw;
    line-height: 2vw;
  }
}