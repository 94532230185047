.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.label {
  flex: 1;
  text-align: left;
}

.checkboxGroupItems {
  grid-gap: 1vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  margin-bottom: 1vw;
}

@media screen and (max-width: 770px) {
  .checkboxGroupItems {
    line-height: 5vw;
    display: flex;
    grid-gap: 0.5vw;
    flex-direction: column;
  }
}