.form {
  margin: 0 auto 50px;
}
.inputContainer {
  position: relative;
}
.reset {
  margin: 5%;
  white-space: nowrap;
  color: #000;
  text-decoration: underline;
}
.eyeButton {
  margin-top: 9%;
  transform: translateY(-50%);
}

.buttons {
  font-size: 35px;
}


@media screen and (max-width: 730px) {
  .inputContainer {
    position: relative;
  }
  
  .eyeButton {
    font-family: 'Amatic SC', cursive;
    font-style: normal;
    font-weight: 400;
    transform: translateY(-50%);
  }
}
