.form {
  margin: 0 auto 40px;
}

.button {
  font-size: 25px;
}

p {
  font-size: 25px;
  font-weight: 700;
}