.single-card {
  margin-top: 10vw;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

div {
  line-height: clamp(14px, 3vw, 60px);
  font-size: clamp(15px, 2vw, 62px);
}

.single-card__buttons {
  display: flex;
  margin-bottom: 40px;
}

.single-card__link {
  color: #000;
}

.single-card__button {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.single-card__button svg {
  margin-right: 5px;
}

.single-card__button:last-child {
  margin-right: 0px;
}

.single-card__edit {
  margin-left: 40px;
  color: #e7003e;;
  text-decoration: underline;
}

.single-card__text, .single-card__text_with_link {
  display: flex;
  align-items: center;
}

.single-card__text svg {
  margin-right: 12px;
}

.single-card__image {
  object-fit: cover;
  align-self: flex-start;
  height: 100%;
  width: 35vw;
  border-radius: 15px;
  max-width: 100%;
}

.single-card__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 60px;
}


.single-card__header-info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 18px;
}

.single-card__title {
  font-family: 'Amatic SC', cursive; 
  font-style: normal; 
  font-weight: 700; 
  font-size: 50px;

  line-height: 49px;
  color: #000;
  margin: 0;
}

@media screen and (max-width: 820px) {
  .single-card__text_with_link {
    display: block;
  }

  .single-card__edit {
    margin-left: 0px;
    margin-top: 20px;
    display: inline-block;
  }

  .single-card {
    display: block;
  }

  .single-card__info {
    padding-left: 0px;
  }

  .single-card__image {
    margin-bottom: 40px;
    height: 100%;
  }

  .single-card__buttons {
    display: block;
  }

  .single-card__button {
    margin-bottom: 16px;
  }
}

