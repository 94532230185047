.main {
  min-height: calc(100vh - 210px);
  margin: 5vw 1vw 0;
}


@media screen and (max-width: 770px) {
  .main {
    min-height: calc(100vh - 170px);
  }
}