.single-card {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.form {
  background: #ffbdd566;
  border: 1px solid #ada6a6;
  border-radius: 16px;
  box-shadow: 0 2px 2px #00000040;
  box-sizing: border-box;
  padding: 2vw 2vw 2vw 2vw;
  max-width: 90%;
  margin-top: 10vw;
}
.fileInput {
  margin-bottom: 72px;
  color: #000;
  font-family: Amatic SC, cursive;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 12px;
  max-width: 340px;
  text-align: left;
}
.fileInput button {
  font-size: 20px;
  background: #ed89e5;
}
.fileInput label {
  color: #000;
  font-family: Amatic SC, cursive;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 12px;
  max-width: 340px;
  text-align: left;
}
.buttons {
  margin: 3vw;
  font-family: 'Amatic SC', cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  background: #a521e2;
  border-radius: 6px;
  padding: 0px 30px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color 0.8s ease-in-out, transform 0.8s ease-in-out, background-color 0.8s ease-in-out;
  outline: none;
  user-select: none;
  cursor: pointer;
  width: max-content;
  box-sizing: border-box;
}
.buttons:active{
  box-shadow: 0 0 7px #d64eff;
}
.menuLink {
  white-space: nowrap;
  color: red;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
  padding: 0;
  font-weight: 700;
  line-height: 19px;
  margin-right: 28px;
  transition: opacity 0.2s;
  width: 7vw;
  margin-left: 40px
}
.menuLink:last-child {
  margin-right: 0px;
}
.menuLink:hover {
  opacity: 0.8;
  color: #64005c
}
.single-card__buttons {
  display: flex;
  margin-bottom: 40px;
}
.single-card__link {
  color: #000;
}
.single-card__button {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.single-card__button svg {
  margin-right: 5px;
}
.single-card__button:last-child {
  margin-right: 0px;
}
.card__edit{
  color: red;
  font-size: 25px;
  font-weight: 900;
  line-height: 19px;
  transition: opacity .2s;
  white-space: nowrap;
  width: 2vw;
}
.card__edit:last-child {
  margin-right: 0px;
}
.card__edit:hover {
  opacity: 0.8;
  color: #64005c
}
.single-card__edit {
  color: red;
  font-size: 25px;
  font-weight: 900;
  line-height: 5vw;
  transition: opacity .2s;
  white-space: nowrap;
  width: 2vw;
}
.single-card__edit:last-child {
  margin-right: 0px;
}
.single-card__edit:hover {
  opacity: 0.8;
  color: #64005c
}
.single-card__image {
  object-fit: cover;
  align-self: flex-start;
  height: 480px;
  width: 480px;
  border-radius: 15px;
  max-width: 100%;
}
.successMessage {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
img {
  height: 100%; 
  max-height: 100%; 
  max-width: 100%; 
  object-fit: cover; 
  width: 100%;
} 
.single-card__info {
  display: flex;
  flex-direction: column;
  padding-left: 4vw;
  width: 100%;
  align-items: baseline;
}
.single-card__header-info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 3vw;
}
.single-card__title {
  font-family: 'Amatic SC', cursive; 
  font-style: normal; 
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #000;
  margin: 0;
}

@media screen and (max-width: 820px) {
  .single-card__text_with_link {
    display: block;
  }
  .buttons {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: opacity .2s;
    margin-left: 1%;
  }
  .menuLink {
    padding: 0 0 0;
    font-size: 2vw;
    margin-right: 2vw;
    width: auto;
  }  
  .single-card__edit {
    display: flex;
    font-size: 2vw;
    margin-left: 1%;
    margin-top: 1vw;
    flex-direction: column;
    align-items: baseline;
  }
  .single-card {
    display: block;
  }
  .single-card__info {
    padding-left: 0px;
  }
  .card__edit{
    line-height: 8vw;
  }
  .single-card__image {
    margin-bottom: 40px;
  }
  .single-card__buttons {
    display: block;
    font-size: 100%;
  }
  .single-card__button {
    margin-bottom: 16px;
  }
}    
@media screen and (max-width: 1100px) {
  .menuLink {
    padding: 0 0 0;
    font-size: 25px;
    width: auto;
    margin-left: 1vw;
    line-height: 6vw;
  }  
  .card__edit{
    line-height: 3vw;
  }
  .single-card__image {
    width: 100%;
    height: 100%;
  }
  .single-card {
    display: flex;
    text-align: left;
    flex-direction: column;
  }
}   
@media screen and (max-width: 600px) {
  .menuLink {
    font-size: 4vw;
    line-height: 8vw;
  }  
  .card__edit{
    font-size: 5vw;
    line-height: 4vw;
  }
} 
@media screen and (max-width: 400px) {
  .menuLink {
    line-height: 11vw;
  }  
  .card__edit{
    line-height: 6vw;
  }
} 