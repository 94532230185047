.form {
  margin: 0 auto 50px;
}


.button {
  font-size: 18px;
}


.fileInput {
  margin-bottom: 72px;
}
.aboutInp {
  margin-left: 5vw;
}


.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteAbout {
  font-size: 18px;
  text-decoration: underline;
  margin-left: 26px;
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .ingredientsInputs {
    display: block;
    text-align: left;
  }

  .ingredientAdd {
    padding-left: 0px;
  }

  .checkboxGroup {
    display: block;
  }

  .checkboxGroupLabel {
    margin-bottom: 10px;
  }

  .checkboxGroupTags {
    display: block;
  }

  .checkboxGroupItem {
    margin-bottom: 6px;
  }

  .cookingTime {
    display: block;
    text-align: left;
  }

  .ingredientsAdded {
    padding-left: 0px;
  }
}