.title {
  background: none;
  font-family: Amatic SC, cursive;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 60px;
  margin-top: 4vw;
  text-align: left;
}
@media screen and (max-width: 1000px) {
  .title {
    margin-top: 13vw;
  }
}
@media screen and (max-width: 770px) {
  .title {
    margin-bottom: 30px;
    font-size: 5vw;
  }
}
